<template>
	<v-lazy transition="fade-transition">
		<div class="render-blocks">
			<div
				v-for="(block, i) in blocks"
				:key="i"
				:class="
					block.name
						.split('/')
						.join('_')
						.split('-')
						.join('_')
				"
			>
				<component
					v-if="block.name !== undefined"
					v-bind:is="
						block.name
							.split('/')
							.join('_')
							.split('-')
							.join('_')
					"
					:block="block"
					:parentBlock="parentBlock ? parentBlock : false"
					:offset="offset"
				/>
			</div>
		</div>
	</v-lazy>
</template>

<script>
	import componentsBlocks from '@/components/blocks';
	export default {
		name: 'RenderBlocks',
		props: ['blocks', 'parentBlock', 'offset'],
	
		components: {
			componentsBlocks: componentsBlocks,
			core_button: () => import('@/components/blocks/core/core_button.vue'),
			core_buttons: () => import('@/components/blocks/core/core_buttons.vue'),
			core_columns: () => import('@/components/blocks/core/core_columns.vue'),
			core_gallery: () => import('@/components/blocks/core/core_gallery.vue'),
			core_heading: () => import('@/components/blocks/core/core_heading.vue'),
			core_image: () => import('@/components/blocks/core/core_image.vue'),
			core_list: () => import('@/components/blocks/core/core_list.vue'),
			core_list_item: () => import('@/components/blocks/core/core_list_item.vue'),
			core_paragraph: () => import('@/components/blocks/core/core_paragraph.vue'),
			core_quote: () => import('@/components/blocks/core/core_quote.vue'),
			core_embed: () => import('@/components/blocks/core/core_embed.vue'),

			lazyblock_about_us: () => import('@/components/blocks/lazyblocks/lazyblock_about_us.vue'),
			lazyblock_abstract: () => import('@/components/blocks/lazyblocks/lazyblock_abstract.vue'),
			lazyblock_aili_products: () => import('@/components/blocks/lazyblocks/lazyblock_aili_products.vue'),
			lazyblock_attention: () => import('@/components/blocks/lazyblocks/lazyblock_attention.vue'),
			lazyblock_buttons_link: () => import('@/components/blocks/lazyblocks/lazyblock_buttons_link.vue'),
			lazyblock_card_slideshow: () => import('@/components/blocks/lazyblocks/lazyblock_card_slideshow.vue'),
			lazyblock_carousel_list: () => import('@/components/blocks/lazyblocks/lazyblock_carousel_list.vue'),
			lazyblock_core_free: () => import('@/components/blocks/lazyblocks/lazyblock_core_free.vue'),
			lazyblock_donation: () => import('@/components/blocks/lazyblocks/lazyblock_donation.vue'),
			lazyblock_events: () => import('@/components/blocks/lazyblocks/lazyblock_events.vue'),
			lazyblock_faq: () => import('@/components/blocks/lazyblocks/lazyblock_faq.vue'),
			lazyblock_google_maps: () => import('@/components/blocks/lazyblocks/lazyblock_google_maps.vue'),
			lazyblock_last_pages: () => import('@/components/blocks/lazyblocks/lazyblock_last_pages.vue'),
			lazyblock_last_posts: () => import('@/components/blocks/lazyblocks/lazyblock_last_posts.vue'),
			lazyblock_last_recipes: () => import('@/components/blocks/lazyblocks/lazyblock_last_recipes.vue'),
			lazyblock_library: () => import('@/components/blocks/lazyblocks/lazyblock_library.vue'),
			lazyblock_listpanel: () => import('@/components/blocks/lazyblocks/lazyblock_listpanel.vue'),
			lazyblock_map_by_categories: () => import('@/components/blocks/lazyblocks/lazyblock_map_by_categories.vue'),
			lazyblock_member_card: () => import('@/components/blocks/lazyblocks/lazyblock_member_card.vue'),
			lazyblock_newsletter: () => import('@/components/blocks/lazyblocks/lazyblock_newsletter.vue'),
			lazyblock_partners: () => import('@/components/blocks/lazyblocks/lazyblock_partners.vue'),
			lazyblock_paypal_code: () => import('@/components/blocks/lazyblocks/lazyblock_paypal_code.vue'),
			lazyblock_private_content: () => import('@/components/blocks/lazyblocks/lazyblock_private_content.vue'),
			lazyblock_profile: () => import('@/components/blocks/lazyblocks/lazyblock_profile.vue'),
			lazyblock_recipe: () => import('@/components/blocks/lazyblocks/lazyblock_recipe.vue'),
			lazyblock_slideshow: () => import('@/components/blocks/lazyblocks/lazyblock_slideshow.vue'),
			lazyblock_subscription: () => import('@/components/blocks/lazyblocks/lazyblock_subscription.vue'),
			lazyblock_subtitle: () => import('@/components/blocks/lazyblocks/lazyblock_subtitle.vue'),
			lazyblock_us_chefs: () => import('@/components/blocks/lazyblocks/lazyblock_us_chefs.vue'),

			qzrstudio_panel: () => import('@/components/blocks/qzr/qzrstudio_panel.vue')
		},
		
	};
</script>

<style lang="scss" scoped>
	.render-blocks {
		width: 100%;
	}
</style>
